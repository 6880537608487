<template>
  <b-modal
    :id="id"
    size="sm"
    title="Báo cáo an toàn vệ sinh lao động"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">

          <b-form-group>
            <label for="basicInput">Loại báo cáo <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="typeReport"
            >
              <v-select
                v-model="dataInput.reportTypeId"
                :reduce="item => item .id"
                label="name"
                :options="dataCombobox"
                :placeholder="'-- Chọn loại báo cáo --'"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Năm <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="year"
              rules="required"
              :custom-messages="year"
            >
              <b-form-input
                v-model="dataInput.year"
                type="number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      typeReport: {
        required: 'Chọn loại báo cáo là bắt buộc',
      },
      year: {
        required: 'Năm là bắt buộc',
      },
      urlQuery: {
        reportLevel: 'Department',
        rType: 'Worker',
      },
      dataCombobox: [],
      dataInput: {
        reportTypeId: '',
        year: '',
      },
    }
  },
  created() {
    this.fecthCombobox(this.urlQuery)
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    async fecthCombobox(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_TYPE_REPORT, {
        params: urlQuery,
      })
      this.dataCombobox = data?.data
    },
    // this.$emit('handleCreateOccupation', this.queryBody, this.$refs.rule)
    // this.$bvModal.show(this.modalUpdateReport)
    resetData() {
      this.dataInput = {}
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
